import React, { useState } from "react";
import { Col, Row, Button, Modal, Form, Container } from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";
import { base_url } from "../Helpers/request";
import "react-toastify/dist/ReactToastify.css";
import "../Assets/css/NewLogin.css";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { cartlengthvalue } from "../Reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { whishlistlength } from "../Reducer/wishlistReducer";
import { sessionId } from "../Reducer/homeReducer";

const NewLogin = () => {
  const history = useHistory();
  const session_Id = useSelector(
    (state) => state.persistedReducer.home.session_id
  );
  const [login, setLogin] = useState(false);
  const [errmsg, setErrmsg] = useState(false);
  const [errmsg2, setErrmsg2] = useState(false);
  const [msg, setMsg] = useState("");
  const [userhover, setUserhover] = useState(false);

  /*search code*/
  const [forgotpassword, setForgotpassword] = useState(false);
  //login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //forgotpass
  const [f_email, setF_email] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const signin = () => {
    setLogin(true);
    setForgotpassword(false);
  };
  const handleClicktop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosetop = () => {
    setAnchorEl(null);
  };
  // useEffect(()=>{
  //     gapi.load('client:auth2', () => {
  //         gapi.client.init({
  //               clientId: "549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com",
  //               plugin_name: "chat"
  //           })
  //       })
  //   },[])
  const handlelogout = () => {
    localStorage.removeItem("bw-user");
    dispatch(whishlistlength(0));
    dispatch(cartlengthvalue(0));
    dispatch(sessionId(""));
    setAnchorEl(null);
    history.push("/");
    window.location.reload(true);
  };
  const handlesubmitlogin = (e) => {
    setErrmsg(false);
    e.preventDefault();
    setButtonLoading(true);
    axios
      .post(
        base_url + "/user/login",
        { email, password, session_id: session_Id ? session_Id : "" },
        {
          headers: {
            // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 1) {
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          history.push("/");
          window.location.reload(true);

          dispatch(cartlengthvalue(response.data.data.total_cart_count));
          dispatch(whishlistlength(response.data.data.total_wish_count));
          localStorage.setItem("bw-user", JSON.stringify(user));
          dispatch(sessionId(""));
          setEmail("");
          setPassword("");
          setButtonLoading(false);
          // setLogin(false);
        } else {
          setButtonLoading(false);
          setErrmsg(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleforgotpassword = (e) => {
    setButtonLoading(true);
    e.preventDefault();
    var data = {
      email: f_email,
    };
    axios
      .post(base_url + "/user/forget_password", data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          setButtonLoading(false);
          setF_email("");
          signin();
          return swal(response.data.message, "", "success");
        } else {
          setButtonLoading(false);
          setErrmsg2(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const responseGoogle = (response) => {
    console.log(response);
    const data = {
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      social_id: response.profileObj.googleId,
      login_method: 2,
      email: response.profileObj.email,
      session_id: session_Id ? session_Id : "",
      language: "EN",
      currency: "USD",
    };
    axios
      .post(`${base_url}/user/socialLogin`, data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          window.location.reload(true);
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          history.push("/");
          dispatch(cartlengthvalue(response.data.data.total_cart_count));
          dispatch(whishlistlength(response.data.data.total_wish_count));
          localStorage.setItem("bw-user", JSON.stringify(user));
          dispatch(sessionId(""));
          setEmail("");
          setPassword("");
          setButtonLoading(false);
          setLogin(false);
        } else {
          setButtonLoading(false);
          setErrmsg(true);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const responseFacebook = (response) => {
    console.log(response);
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  const isLogin = JSON.parse(localStorage.getItem("bw-user"))
    ? JSON.parse(localStorage.getItem("bw-user")).isLogin
    : false;
  const user = JSON.parse(localStorage.getItem("bw-user"))
    ? JSON.parse(localStorage.getItem("bw-user"))
    : [];
  return (
    <>
      <div className="hl_new_login_page_section hl_new_register_section">
        <Container>
          {forgotpassword ? (
            <Row>
              <Col sm={12} md={{ span: 6, offset: 3 }}>
                <div className="rcs_appointment_input rcs_login rcs_forgot">
                  <Modal.Title id="contained-modal-title-vcenter">
                    Forgot Password
                  </Modal.Title>
                  <div className="rcs_forgot_msg text-left">
                    <span>
                      To reset your password, please enter your email address{" "}
                    </span>
                  </div>
                  <Form
                    onSubmit={handleforgotpassword}
                    className="hl_new_login_form"
                  >
                    <Row>
                      <Col>
                        {/* <TextField
                                        id="outlined-basic"
                                        label="Email Address"
                                        type="Email"
                                        variant="outlined"
                                        value={f_email}
                                        onChange={(e) => setF_email(e.target.value)}
                                        required /> */}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="Email"
                            value={f_email}
                            onChange={(e) => setF_email(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="rcs_fill_button"
                          type="submit"
                          disabled={buttonLoading}
                        >
                          Continue{" "}
                          {buttonLoading ? (
                            <CircularProgress className="rcs_loader" />
                          ) : (
                            ""
                          )}
                        </Button>
                      </Col>
                    </Row>
                    {errmsg2 ? (
                      <Row>
                        <Col>
                          <div className="rcs_signup_link rcs_signup_link_error">
                            <p style={{ color: "red" }}>{msg}</p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col>
                        <div className="rcs_signup_link">
                          <p>
                            Remember your password ?{" "}
                            <NavLink
                              to="#"
                              onClick={() => setForgotpassword(false)}
                              className="mb-0"
                            >
                              {" "}
                              &nbsp; Login
                            </NavLink>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12} md={{ span: 6, offset: 3 }} className="m-auto">
                <div className="rcs_appointment_input rcs_login p-0">
                  <div id="contained-modal-title-vcenter">Log in</div>
                  <Form
                    onSubmit={handlesubmitlogin}
                    className="hl_new_login_form"
                  >
                    <Row>
                      <Col>
                        {/* <TextField
                                        id="outlined-basic"
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required /> */}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {/* <TextField
                                        id="outlined-basic"
                                        label="passwordpassword"
                                        type="password"
                                        variant="outlined"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    /> */}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            size="lg"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <NavLink
                          className="rcs_login_forgot rcs_forogt_pass hl_new_login_btnn"
                          to="#"
                          onClick={() => setForgotpassword(true)}
                        >
                          Forgot your password?
                        </NavLink>
                      </Col>
                    </Row>
                    {errmsg ? (
                      <Row>
                        <Col>
                          <div className="rcs_signup_link rcs_signup_link_error">
                            <p>{msg}</p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col>
                        <Button
                          className="hl_new_black_btn mb-4"
                          type="submit"
                          disabled={buttonLoading}
                        >
                          Sign In{" "}
                          {buttonLoading ? (
                            <CircularProgress className="rcs_loader" />
                          ) : (
                            ""
                          )}
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="rcs_signup_link">
                          {/* <p>Don't have an account ? <NavLink to="/register" onClick={() => setLogin(false)} >Sign Up</NavLink></p> */}

                          <NavLink
                            to="/register"
                            onClick={() => setLogin(false)}
                            className="hl_new_white_btn"
                          >
                            Create an account
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="hl_new_login_Wholesaler">
                          {/* <p>  <span><NavLink to="/register" onClick={() => setLogin(false)} >Sign Up </NavLink> </span> &nbsp; Don't have an account ?</p> */}
                          <h6>Are you a Jeweler or Wholesaler?</h6>
                          <p>
                            Please follow the link below and submit your request
                            for access to the wholesale site.
                          </p>
                          <NavLink
                            to="/register-pro"
                            onClick={() => setLogin(false)}
                            className="hl_new_white_btn"
                          >
                            Register as wholesaler
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  {/* <Row>
                            <Col>
                                <div className="rcs_google_signup_main">
                                    <GoogleLogin
                                        clientId="549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com"
                                        buttonText="Sign in with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </div>
                            </Col>
                        </Row> */}

                  {/* <FacebookLogin
                        appId="1088597931155576"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="my-facebook-button-class"
                        icon="fa-facebook"
                        /> */}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default NewLogin;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutImg from "../../../../Assets/images/hala-london-img/about-img.webp";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Virtualappointment = () => {
  const history = useHistory();
  return (
    <>
      <section className="rcs_color_virtual_section1">
        <Container className="hl_custome_container">
          <Row className="rcs_virtual_appointment_row">
            <Col lg={6} sm={12} md={6} className="rcs_order_cs">
              <div className="rcs_virtual_appointment_text">
                <h2> About Hala Diamond London </h2>
                <p>
                  Hala London Diamonds in the field of Diamond industry with an
                  experience of over 20 years. We are established firm providing
                  services to our customers. The founders of Hala London
                  Diamonds are both graduates of the Gemological Institute of
                  America.
                </p>
                <button
                  className="hl_btn_outline"
                  onClick={() => {
                    history.push("/our-story");
                    window.scrollTo(0, 0);
                  }}
                >
                  Know More
                </button>
              </div>
            </Col>
            <Col lg={6} sm={12} md={6}>
              <div className="rcs_virtual_appointment_img">
                <LazyLoadImage
                  src={AboutImg}
                  alt="About Us Image"
                  className="img-fluid"
                  title="About Us"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Virtualappointment;
